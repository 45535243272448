import { Helmet } from "react-helmet"


export const AboutPage = () => {
    return (
        <>
            <Helmet>
                <title> Wireless Engineering Ltd Co. - About </title>
                <meta name="description" content="Wireless Engineering Ltd Co. - About" />
                <meta name="keywords" content="Wireless Engineering Ltd Co.,About" />

                <meta property="og:title" content="Wireless Engineering Ltd Co. - About" />
                <meta property="og:description" content="Wireless Engineering Ltd Co. - About" />
            </Helmet>
            <div className="about__bg home__layout ">
                <div
                    style={{
                        color: '#fff',
                        padding: '20px',
                    }}
                    className="services__first-container"
                >
                    <h1 className="mb-5">About</h1>
                    <h4 className="mb-4">A World Without Wires™</h4>
                    <p style={{ fontSize: '1.3rem' }}>Wireless Engineering Ltd Co. was founded in 2003 (first as a DBA business, under the name of Wireless Engineering) and then incorporated in 2013 under the current name of Wireless Engineering Ltd Co.</p>
                </div>

                <div
                    style={{
                        fontSize: '1.3rem',
                        color: '#fff',
                        padding: '20px',
                    }}
                    className="services__first-container"
                >
                    <p><strong>Proven Competency -</strong> With more than 15 years in business, we have proven our worth!</p>
                    <p><strong>Reliability -</strong> Many of our repeated customers started doing business with us since our inception in 2003!</p>
                    <p><strong>Exponential Growth -</strong> Our business model does not allow for anything else than exponential growth!</p>
                    <p><strong>We specialize in new ideas evaluation, market pre-evaluation, prototype creation and optimization, small-quantity production for market-testing, and product launch with related marketing.</strong></p>
                </div>
            </div>

            <div className="about__second-bg home__layout">
                <div
                    style={{
                        color: '#fff',
                        padding: '20px',
                    }}
                    className="services__first-container"
                >
                    <h1 className="mb-5">New Products Ideas</h1>
                    <p style={{
                        fontSize: '1.3rem',
                    }}>We specialize in new ideas evaluation, market pre-evaluation, prototype creation and optimization, small-quantity production for market-testing, and product launch with related marketing.</p>
                </div>

                <div>
                    <div
                        style={{
                            fontSize: '1.3rem',
                            color: '#fff',
                            padding: '20px',
                        }}
                        className="services__first-container"
                    >
                        <p><strong>New Product Evaluation -</strong>  With our 15 years of experience, we will honestly tell you if your idea is worth pursuing and if we can help.</p>
                        <p><strong>Market Evaluation  -</strong> We have strategies to test a particular market niche before you invest capital in a new product.</p>
                        <p><strong>Prototype and Marketing -</strong> We have launched hundreds of brand-name products with minimal investment.</p>
                        <p><strong>We can assist you through all stages of product development and launch your product at reasonable costs and fast turnaround time!</strong></p>
                    </div>
                </div>
            </div>

            <div className="home__layout">
                <div className="about__founder-flexbox">
                    <div>
                        <div className="about__sabino-image-container">
                            <img
                                src="https://wirelessengineeringimages.s3.amazonaws.com/SABINO.jpg"
                                alt="sabino image"
                                className="about__sabino-image"
                            />
                        </div>
                    </div>

                    <div className="about__founder-container">
                        <div className="founder-content">
                            <h4
                                className="mb-5 mt-2"
                                style={{
                                    color: '#131D5C',
                                    fontWeight: 'bold',
                                    fontSize: '2rem',
                                }}
                            >Meet Our Founder</h4>
                            <h5 style={{
                                color: '#131D5C',
                                fontWeight: 'bold',
                                fontSize: '1.6rem',
                            }}>Sabino Leone</h5>
                            <p style={{
                                color: '#747474',
                                fontWeight: 'bold',
                                fontSize: '1.3rem',
                            }}>Founder</p>
                            <p style={{
                                color: '#747474',
                                fontWeight: 'bold',
                                fontSize: '1rem',
                            }}>Sabino Leone is an Electrical Engineer from the University of Houston (BSEE) and founded Wireless Engineering Ltd Co. in 2003.</p>
                            <p style={{
                                color: '#747474',
                            }}>Sabino’s interest in science was very evident since his first years of life. At the age of three, he liked to disassemble mechanical alarm clocks to remove the gears and make spinning tops of all kinds out of them (his father was not very happy about that, and got late to work a few times because of him)…he switched from mechanical curiosity to electronics around the age of six, then started with chemistry around the age of ten and back to electronics at thirteen. His first telecom project was a DIY CB booster and antenna, which allowed him to communicate with people as far as thousands of miles away. At age 15, Sabino built a DIY/designed “Moog synthesizer” which was shown on prime time TV, and then another of his own design that he sold to a career musician. At age 18, he built a personal computer from a DIY project, which gave him the opportunity to discover the world of computer science. At age 19, he built an FM radio transmitter and related antennas and opened his own commercial radio station, which tremendously increased his hands-on telecom experience and also gave him the opportunity to explore audio engineering and electronic music as as well. His first patent, a radio station computer-based automation system, was filed at the age of 22.</p>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}
