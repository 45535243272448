import { useNavigate } from 'react-router-dom';

import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { IconContext } from 'react-icons';

import { TiLightbulb, TiVideo } from 'react-icons/ti';
import { RiMicroscopeLine, RiSpaceShipLine } from 'react-icons/ri';
import { BiRocket } from 'react-icons/bi';
import { GiEarthAmerica } from 'react-icons/gi';
import { Helmet } from 'react-helmet';

import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

export const HomePage = () => {

    const navigate = useNavigate();

    const percentage1 = 96;
    const percentage2 = 95;
    const percentage3 = 92;
    const percentage4 = 98;

    const handlePublications = () => {
        navigate('/publications/');
    }

    return (
        <>
            <Helmet>
                <title> Wireless Engineering Ltd Co. </title>
                <meta name="description" content="Wireless Engineering Ltd Co." />
                <meta name="keywords" content="Wireless Engineering Ltd Co." />

                <meta property="og:title" content="Wireless Engineering Ltd Co." />
                <meta property="og:description" content="Wireless Engineering Ltd Co." />
            </Helmet>
            
            <div className='bg-home home__layout'>
                <div className="home__first-container">
                    <h1 style={{
                        color: '#fff',
                    }} className="mt-5 mb-5">Unparalleled, Multi-Disciplinary Experience</h1>

                    <p style={{
                        fontSize: '1.5rem',
                        color: '#fff',
                    }} className="mt-3 mb-5">Nowadays, being highly specialized in a particular field is a definite advantage, unless you want to create new ideas, in which case a multi-disciplinary, hands-on experience is the key to success!</p>
                </div>
                <div style={{
                    fontSize: '1.3rem',
                    color: '#fff',
                }}>
                    <p><TiLightbulb style={{ marginRight: '0.5rem' }} /><strong>Science -</strong> A deep knowledge of the Laws of Physics is essential for creating a new high-tech product</p>
                    <p><TiLightbulb style={{ marginRight: '0.5rem' }} /><strong>Aesthetics -</strong> A scientist needs to be a talented artist as well. Leonardo da Vinci is the perfect example of the best possible scientist.</p>
                    <p><TiVideo style={{ marginRight: '0.5rem' }} /><strong>Marketing -</strong> Without proper marketing, a product will very rarely be successful.</p>
                    <p><strong>We can assist you on all phases of product development and launch.</strong></p>

                </div>
                <Row className='mt-4 g-4 mb-4 d-flex justify-content-around'>
                    <Col className="home__col-style" xs={12} md={6} lg={3}>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <IconContext.Provider value={{ size: '2.5rem', color: '#131D5C' }}>
                                <RiMicroscopeLine className='mb-3' />
                            </IconContext.Provider>
                            <h2>Idea Evaluation</h2>
                            <p style={{
                                fontSize: '1.2rem', textAlign: 'center',
                            }}>We will give you a straight-forward, honest opinion regarding your idea, or set of ideas.</p>

                        </div>
                    </Col>

                    <Col className="home__col-style" xs={12} md={6} lg={3}>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <IconContext.Provider value={{ size: '2.5rem', color: '#131D5C' }}>
                                <BiRocket className='mb-3' />
                            </IconContext.Provider>
                            <h2>Market Evaluation</h2>
                            <p style={{
                                fontSize: '1.2rem', textAlign: 'center',
                            }}>Because of our vast experience, we can evaluate your product ideas quickly and with good accuracy.</p>
                        </div>
                    </Col>

                    <Col className="home__col-style" xs={12} md={6} lg={3}>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <IconContext.Provider value={{ size: '2.5rem', color: '#131D5C' }}>
                                <RiSpaceShipLine className='mb-3' />
                            </IconContext.Provider>
                            <h2>Prototyping</h2>
                            <p style={{
                                fontSize: '1.2rem', textAlign: 'center',
                            }}>Our in-house laboratories and vast network of suppliers will ensure a very quick and accurate realization of your prototype.</p>

                        </div>
                    </Col>

                    <Col className="home__col-style mb-4" xs={12} md={6} lg={3}>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <IconContext.Provider value={{ size: '2.5rem', color: '#131D5C' }}>
                                <GiEarthAmerica className='mb-3' />
                            </IconContext.Provider>
                            <h2>Globalization</h2>
                            <p style={{
                                fontSize: '1.2rem', textAlign: 'center',
                            }}>Why sell to one country when there is enough demand in other countries as well? Globalization is usually the quickest way to increase sales!</p>

                        </div>
                    </Col>
                </Row>
            </div>

            <div className='home__layout bg-circles'>
                <Row className="g-4 justify-content-md-center">
                    <Col xs={12} md={6} xl={3} className=''>
                        <CircularProgressbar
                            value={percentage1}
                            text={`${percentage1}%`}
                            className="mb-4"
                            strokeWidth={4}
                            styles={buildStyles({
                                textColor: "white",
                                pathColor: "white",
                                trailColor: "#131D5C"
                            })}
                        />
                        <p style={{ textAlign: 'center', color: 'white' }}>ON-TIME PROTOTYPE CREATION</p>
                    </Col>

                    <Col xs={12} md={6} xl={3}>
                        <CircularProgressbar
                            value={percentage2}
                            text={`${percentage2}%`}
                            className="mb-4"
                            strokeWidth={4}
                            styles={buildStyles({
                                textColor: "white",
                                pathColor: "white",
                                trailColor: "#131D5C"
                            })}
                        />
                        <p style={{ textAlign: 'center', color: 'white' }}>MARKET EVALUATION ACCURACY</p>
                    </Col>

                    <Col xs={12} md={6} xl={3}>
                        <CircularProgressbar
                            value={percentage3}
                            text={`${percentage3}%`}
                            className="mb-4"
                            strokeWidth={4}
                            styles={buildStyles({
                                textColor: "white",
                                pathColor: "white",
                                trailColor: "#131D5C"
                            })}
                        />
                        <p style={{ textAlign: 'center', color: 'white' }}>MARKET PREDICTION ACCURACY</p>
                    </Col>

                    <Col xs={12} md={6} xl={3}>
                        <CircularProgressbar
                            value={percentage4}
                            text={`${percentage4}%`}
                            className="mb-4"
                            strokeWidth={4}
                            styles={buildStyles({
                                textColor: "white",
                                pathColor: "white",
                                trailColor: "#131D5C"
                            })}
                        />
                        <p style={{ textAlign: 'center', color: 'white' }}>OVERALL DEADLINE COMPLIANCE</p>
                    </Col>
                </Row>
            </div>

            <div className="home__layout">

                <h3
                    style={{
                        textAlign: 'center',
                    }}
                    className="mt-4 mb-4"
                >Our Lastest Articles</h3>

                <div className='home__articles-flexbox'>
                    <div className='home__articles-container'>
                        <div className='home__articles-card'>
                            <h4
                                onClick={handlePublications}
                                style={{
                                    fontWeight: 'bold',
                                    color: '#131D5C',
                                    cursor: 'pointer',
                                }}
                            >Is My Product Still Sellable If It’s Not Patentable?</h4>
                            <p>Yes, patentability doesn't really mean anything, in facts, there are thousands and thousands (if not hundreds of thousands) of patents that will never become tangible products, even though they were filed for such purpose. The opposite [...]</p>
                        </div>

                        <div className='home__articles-card'>
                            <h4 
                                onClick={handlePublications}
                                style={{
                                    fontWeight: 'bold',
                                    color: '#131D5C',
                                    cursor: 'pointer'
                                }}
                            >How to Patent Your Invention Globally</h4>
                            <p>Patents are usually valid only in the country they're filed in, however, the USPTO does offer a feature to facilitate international patenting under the Patent Cooperation Treaty (PCT). This article is one of our favorites: [...]</p>
                        </div>

                        <div className='home__articles-card'>
                            <h4 
                                onClick={handlePublications}
                                style={{
                                    fontWeight: 'bold',
                                    color: '#131D5C',
                                    cursor: 'pointer'
                                }}
                            >Is Patenting My Product Really Necessary?</h4>
                            <p>The answer is, of course, "it depends"! Is your idea/product a real innovation? Is your idea/product even patentable (i.e., useful, non-obvious, etc.?) You may want to read this article which explains in some details this [...]</p>
                        </div>
                    </div>

                    <div>
                        <div className='home__articles-image-container'>
                            <img
                                src='https://wirelessengineeringimages.s3.amazonaws.com/ALBERT+EINSTEIN.jpg'
                                className='home__articles-image'
                                alt='Albert Einstein'
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <div>

                    </div>
                </div>


            </div>

        </>
    )
}
