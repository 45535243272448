import { useFormik } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';

export const ContactPage = () => {

    const form = useRef<any>();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const { handleSubmit, errors, touched, getFieldProps } = useFormik({
        initialValues: {
            Name: '',
            last: '',
            email: '',
            message: '',
            image: '',
        },
        onSubmit: values => {
            console.log(values);
            emailjs.sendForm('service_elxo8mr', 'template_v7m5tbs', form.current, '8_6TH-IxPPzxN9XPY')
                .then((res) => {
                    console.log(res.text);
                    Toast.fire({
                        icon: 'success',
                        title: 'Form sent successfully'
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        validationSchema: Yup.object({
            Name: Yup.string()
                .max(40, 'Must be 40 characters or less')
                .required('Required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            last: Yup.string()
                .max(40, 'Must be 40 characters or less')
                .required('Required'),
            message: Yup.string()
                .max(1000, 'Must be 1000 characters or less')
                .required('Required')
        })
    });

    return (
        <>
            <Helmet>
                <title> Wireless Engineering Ltd Co. - Contact Us </title>
                <meta name="description" content="Wireless Engineering Ltd Co. - Contact Us" />
                <meta name="keywords" content="Wireless Engineering Ltd Co.,Contact Us" />

                <meta property="og:title" content="Wireless Engineering Ltd Co. - Contact Us" />
                <meta property="og:description" content="Wireless Engineering Ltd Co. - Contact Us" />
            </Helmet>
            <div className="contact__bg home__layout">
                <div
                    style={{
                        color: '#fff',
                        padding: '20px',
                    }}
                    className="services__first-container"
                >
                    <h1 className="mb-5">Contact Us</h1>
                    <h3 className="mb-5">Get in touch with Wireless Engineering Ltd Co.</h3>
                    <p style={{ fontSize: '1.3rem' }}>At Wireless Engineering Ltd Co., we’re constantly seeking to add team members with high integrity, honesty, dedication, perseverance, passion, and excitement about life.</p>
                    <p style={{ fontSize: '1.3rem' }}>We find the future absolutely fascinating, because the future is driven by science and technology, which we deeply love!</p>
                    <p style={{ fontSize: '1.3rem' }} >+1-877-524-5115</p>
                </div>

                <div
                    style={{
                        color: '#fff',
                        padding: '20px',
                    }}
                >
                    <h4 className="mb-4">How Can We Help?</h4>
                    <p style={{ fontSize: '1.3rem' }}>You may contact us for anything related to science, technology, or anything that makes life exciting!</p>
                </div>

                <form ref={form} onSubmit={handleSubmit} className="contact__first-container">
                    <div className="contact__form-container">

                        <input
                            type="text"
                            placeholder="Name"
                            {...getFieldProps('Name')}
                            autoComplete="off"
                            className='contact__input mt-5'
                        />

                        {touched.Name && errors.Name && <p className="contact__error"> {errors.Name} </p>}

                        <input
                            type="text"
                            {...getFieldProps('last')}
                            placeholder="Last"
                            autoComplete="off"
                            className='contact__input mt-5'
                        />

                        {touched.last && errors.last && <p className="contact__error"> {errors.last} </p>}

                        <input
                            type="email"
                            {...getFieldProps('email')}
                            placeholder="Email"
                            autoComplete="off"
                            className='contact__input mt-5'
                        />

                        {touched.email && errors.email && <p className="contact__error"> {errors.email} </p>}

                        <textarea
                            {...getFieldProps('message')}
                            className="contact__textarea mt-5"
                            placeholder="Comment or Message"
                        ></textarea>

                        {touched.message && errors.message && <p className="contact__error"> {errors.message} </p>}
                        <button
                            type='submit'
                            className="button button-block mt-5"
                        >
                            Send
                        </button>
                    </div>
                </form>

            </div>

        </>
    )
}
