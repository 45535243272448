import { Helmet } from "react-helmet"


export const PublicationsPage = () => {
    return (
        <>
            <Helmet>
                <title> Wireless Engineering Ltd Co. - Publications </title>
                <meta name="description" content="Wireless Engineering Ltd Co. - Publications" />
                <meta name="keywords" content="Wireless Engineering Ltd Co.,Publications" />

                <meta property="og:title" content="Wireless Engineering Ltd Co. - Publications" />
                <meta property="og:description" content="Wireless Engineering Ltd Co. - Publications" />
            </Helmet>
            <div className="home__layout mb-3 services__first-container">
                <div
                    style={{
                        padding: '20px',
                        color: '#131D5C',
                    }}>
                    <h1 className="mb-5" style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>Publications</h1>
                    <h3 className="mb-4" style={{ fontSize: '2rem', fontWeight: 'bold' }}>Latest Publications</h3>
                    <p style={{ fontSize: '1.3rem', color: '#747474' }}>Praesent lectus leo, convallis id neque nec, ultrices euismod nibh. Sed ac rhoncus quam. Fusce tristique tellus diam, vel porta eros iaculis vitae.</p>
                </div>

                <div style={{
                    fontSize: '1.3rem',
                    padding: '20px',
                }}>
                    <h3 style={{
                        color: '#131D5C', fontSize: '2rem', fontWeight: 'bold'
                    }} className="mb-4">Is My Product Still Sellable If It’s Not Patentable?</h3>
                    <p style={{ fontSize: '1.3rem', color: '#747474' }} className="mb-5">Yes, patentability doesn’t really mean anything, in facts, there are thousands and thousands (if not hundreds of thousands) of patents that will never become tangible products, even though they were filed for such purpose. The opposite is also true, that is, there are many, many products that could have been patented but were not, for one reason or another (the main reason being the high cost of patenting). This articles elaborates on the subject with some details.</p>
                    <h3 style={{
                        color: '#131D5C', fontSize: '2rem', fontWeight: 'bold'
                    }} className="mb-4">Is Patenting My Product Really Necessary?</h3>
                    <p style={{ fontSize: '1.3rem', color: '#747474' }} className="mb-5">The answer is, of course, “it depends”! Is your idea/product a real innovation? Is your idea/product even patentable (i.e., useful, non-obvious, etc.?) You may want to read this article which explains in some details this matter…</p>
                    <h3 style={{
                        color: '#131D5C', fontSize: '2rem', fontWeight: 'bold'
                    }} className="mb-4">How to Patent Your Invention Globally</h3>
                    <p style={{ fontSize: '1.3rem', color: '#747474' }} className="mb-5">Patents are usually valid only in the country they’re filed in, however, the USPTO does offer a feature to facilitate international patenting under the Patent Cooperation Treaty (PCT). This article is one of our favorites: <a href="https://www.wipo.int/pct/en/faqs/faqs.html" style={{ textDecoration: 'none', color:'turquoise'}}>PCT FAQs</a> </p>
                </div>

                <div>
                    <div
                        style={{
                            padding: '20px',
                        }}>
                        <h1 className="mb-5" style={{ color: '#131D5C', fontSize: '2.5rem', fontWeight: 'bold' }}>In The Press</h1>
                        <p style={{ fontSize: '1.3rem', color: '#747474' }} className="mb-5">Praesent lectus leo, convallis id neque nec, ultrices euismod nibh. Sed ac rhoncus quam. Fusce tristique tellus diam, vel porta eros iaculis vitae.</p>
                    </div>

                    <div
                        style={{
                            padding: '20px',
                        }}>
                        <h1 className="mb-5" style={{ color: '#131D5C', fontSize: '2.5rem', fontWeight: 'bold' }}>On-Going Research</h1>
                        <p style={{ fontSize: '1.3rem', color: '#747474' }} className="mb-5">Praesent lectus leo, convallis id neque nec, ultrices euismod nibh. Sed ac rhoncus quam. Fusce tristique tellus diam, vel porta eros iaculis vitae.</p>
                    </div>

                    <div
                        style={{
                            padding: '20px',
                        }}>
                        <h1 className="mb-5" style={{ color: '#131D5C', fontSize: '2.5rem', fontWeight: 'bold' }}>Discover the Future</h1>
                        <p style={{ fontSize: '1.3rem', color: '#747474' }} className="mb-5">We are a multi-disciplinary laboratory committed to focussed and efficient drug discovery</p>
                    </div>
                </div>
            </div>
        </>
    )
}
