import { Route, Routes } from 'react-router-dom';

import { AboutPage, ServicesPage, PublicationsPage, ContactPage } from '../components/pages';
import { HomePage } from '../components/pages/HomePage';
import { Footer, NavbarComponent } from '../components/ui';


export const AppRouter = () => {
    return (
        <>
            <NavbarComponent />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about/" element={<AboutPage />} />
                <Route path="/services/" element={<ServicesPage />} />
                <Route path="/publications/" element={<PublicationsPage />} />
                <Route path="/contact/" element={<ContactPage />} />
            </Routes>
            <Footer />
        </>
    )
}
