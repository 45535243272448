import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

export const ServicesPage = () => {

    const navigate = useNavigate();

    const handlePublications = () => {
        navigate('/publications/');
    }

    return (
        <>
            <Helmet>
                <title> Wireless Engineering Ltd Co. - Services </title>
                <meta name="description" content="Wireless Engineering Ltd Co. - Services" />
                <meta name="keywords" content="Wireless Engineering Ltd Co.,Services" />

                <meta property="og:title" content="Wireless Engineering Ltd Co. - Services" />
                <meta property="og:description" content="Wireless Engineering Ltd Co. - Services" />
            </Helmet>
            <div className="services__first-bg home__layout">
                <div
                    style={{
                        color: '#fff',
                        padding: '20px',
                    }}
                    className="services__first-container"
                >
                    <h1 className="mb-5">What we do</h1>
                    <p style={{ fontSize: '1.3rem' }}>We assist entrepreneurs, scientists, and anyone with product ideas during the cumbersome process of creating a brand, patenting products, launching products, and all other “technical” aspects of such venture (we do not provide business services or advice, however, since those are not “technical” aspects).</p>
                </div>

                <div>
                    <div style={{
                        fontSize: '1.3rem',
                        color: '#fff',
                        padding: '20px',
                    }} className="services__first-container">
                        <p><strong>Science -</strong> A deep knowledge of the Laws of Physics is essential for creating a new high-tech product</p>
                        <p><strong>Aesthetics -</strong> A scientist needs to be a talented artist as well. Leonardo da Vinci is the perfect example of the best possible scientist.</p>
                        <p><strong>Marketing -</strong> Without proper marketing, a product will very rarely be successful.</p>
                        <p><strong>Simply contact us to see if you we’re a perfect match for your needs (please do not disclose any sensitive information with your first message to us, as we need to first establish if a cooperation would be useful before discussing any details).
                        </strong></p>

                        <Button
                            style={{
                                backgroundColor: '#125BD5',
                                textTransform: 'uppercase',
                                fontSize: '1.3rem',
                                fontWeight: 'bold',
                                padding: '10px',
                            }}
                            onClick={handlePublications}
                        >
                            Latest Publications
                        </Button>
                    </div>
                </div>
            </div>

            <div className="services__second-bg home__layout">
                <div
                    style={{
                        color: '#fff',
                        padding: '20px',
                    }} className="services__first-container">
                    <h1 className="mb-5">Idea/Product Assesment</h1>
                    <p style={{ fontSize: '1.3rem' }}>The very first step in our cooperation is to understand clearly what your needs are. We receive requests for assistance from people with very diverse background, ideas, needs, and we’re a great match with only about 5% of all requests we receive. Still, we don’t mean to discourage you from even getting in touch with us for a few, preliminary questions, so feel free to contact us even if you’re quite unsure about all of the above!</p>
                    <Button
                        style={{
                            backgroundColor: '#125BD5',
                            textTransform: 'uppercase',
                            fontSize: '1.3rem',
                            fontWeight: 'bold',
                            padding: '10px',
                        }}
                        onClick={handlePublications}
                    >
                        Our Approach
                    </Button>
                </div>
            </div>

        </>
    )
}
