import { BrowserRouter } from 'react-router-dom';

import { AppRouter } from './router/AppRouter';

export const WirelessEngWeb = () => {
    return (
        <BrowserRouter>
            <AppRouter />
        </BrowserRouter>
    )
}
