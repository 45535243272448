import { Container } from 'react-bootstrap';

export const Footer = () => {
    return (
        <>
            <div className="footer__container mobileFooterContainer" id="navFooter">
                <a href="#nav-top">
                    <div className="footer__back-top pointer">
                        <h2>Back to top</h2>
                    </div>
                </a>
                <div className="footer__nav-footer">

                    <Container>
                        <div className='d-flex flex-column align-items-center'>
                            <div className="footer__logo-container">
                                <img
                                    src="https://wirelessengineeringimages.s3.amazonaws.com/Wireless+Engineering+LOGO2-04.jpg"
                                    alt="Logo"
                                    className="footer__logo"
                                    id="nav-top"
                                />
                            </div>

                            <div className="footer__rights-container">
                                <p>© Copyright 2018 - Wireless Engineering Ltd Co.</p>
                            </div>

                        </div>
                    </Container>
                </div>
            </div>

            {/* Footer Responsive */}

            <footer className="navFooterMobile">
                <div id="nav-footer" className="footer__navFooterGateway">
                    <a id="mblFooter-gototop" className="navFooter-topPage" href="#nav-top">
                        <i className="fa-solid fa-caret-up navIcon"></i>
                        <b className="nav-b">Top of Page</b>
                    </a>
                    <div className='footer__mobile-container'>

                        <Container>
                            <div className='d-flex flex-column align-items-center '>
                                <div className="footer__logo-container">
                                    <img
                                        src="https://wirelessengineeringimages.s3.amazonaws.com/Wireless+Engineering+LOGO2-04.jpg"
                                        alt="Logo"
                                        className="footer__logo"
                                        id="nav-top"
                                    />
                                </div>
                            </div>

                            <div className="footer__mobilerights-container">
                                <p>© Copyright 2018 - Wireless Engineering Ltd Co.</p>
                            </div>
                        </Container>
                    </div>



                </div>
            </footer>
        </>
    )
}
